export default {
  siteTitle: 'Get bonus',
  blogerName: 'BRABOS',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@brabodocasino'
    },
    {
      name: 'telegram',
      url: 'https://t.me/brabodocasino'
    },
    {
      name: 'whatsapp',
      url: 'https://chat.whatsapp.com/Hbi9UVaHSUz7DCz0vLahKT'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/brabodocasinohttps://legzocasino.life/c47a9238d'
    }
  ],
  projects: [
    {
      name: 'starda',
      url: 'https://stardacasino.life/c853ba1d4',
      gameTitle: 'Forty Fruity Million (BGaming)',
    },
    {
      name: 'izzi',
      url: 'https://izzicasino.life/c7047b3da',
      gameTitle: 'Avalon: The Lost Kingdom (BGaming)',
    },
    {
      name: 'sol',
      url: 'https://solcasino.life/c3f19450c',
      gameTitle: 'Doors Of Sol (BGaming)',
    },
    {
      name: 'jet',
      url: 'https://jetcasino.life/c69c48913',
      gameTitle: 'Candy Monsta (BGaming)',
    },
    {
      name: 'fresh',
      url: 'https://freshcasino.life/cc33df2a7',
      gameTitle: 'Doors Of Fresh (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>BRABOS</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'BRABOS',
  casinoBonus1: '<strong>100% + 500FS</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  bonusesText: 'GIROS GRATIS',
  copyMessage: 'Código promocional copiado',
  theme: 'dark',
};
